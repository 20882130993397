import { lazy } from "react";
import { Navigate } from "react-router";
import FirstLogin from "src/components/first-login";
import AuthGuard, {
  HideForImpersonate,
  HideMenus,
  HidePartner,
  UserGuard,
} from "src/guards/AuthGuard";
import UserLayout from "src/layouts/user";
import Loadable from "../Loadable";
import businessBuilder from "./businessBuilder";
import financial from "./financial";
import genealogy from "./genealogy";
import helpCenter from "./helpCenter";
import profile from "./profile";
import subscriptions from "./subscriptions";
import tools from "./tools";
const ProductList = Loadable(
  lazy(() =>
    import(
      "src/pages/userSide/onlineStore/productSubscription/productList/index"
    )
  )
);
const ProductDetails = Loadable(
  lazy(() =>
    import("src/pages/userSide/onlineStore/productSubscription/details/index")
  )
);
const Checkout = Loadable(
  lazy(() =>
    import("src/pages/userSide/onlineStore/productSubscription/checkout/index")
  )
);
const MyOrders = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/myOrders/index"))
);
const PartnerPackage = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/partnerpackage"))
);
const SponserPackage = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/sponserpackage"))
);
const SparToken = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/spartoken"))
);

const InvoiceDetails = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);

const BlogPosts = Loadable(
  lazy(() => import("src/pages/userSide/blogs/BlogPosts"))
);
const BlogPost = Loadable(
  lazy(() => import("src/pages/userSide/blogs/BlogPost"))
);

const Dashboard = Loadable(
  lazy(() => import(`src/pages/userSide/dashboard/index`))
);

const IncomeReport = Loadable(
  lazy(() => import("src/pages/userSide/incomeReport"))
);
const MissedPoints = Loadable(
  lazy(() => import("src/pages/userSide/missedPoints"))
);

const Sponser = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/sponsor"))
);

const FixedRate = Loadable(
  lazy(() => import("src/pages/userSide/onlineStore/fixedRate/index"))
);

const Stake = Loadable(lazy(() => import("src/pages/stake/index")));

const Notices = Loadable(
  lazy(() => import("src/pages/userSide/notices/index"))
);

const InvestorAmc = Loadable(
  lazy(() => import("src/pages/userSide/investortAmcPortal/index"))
);

const user = {
  path: "user",
  element: (
    <AuthGuard>
      <UserGuard>
        {/* <FloatingAction /> */}
        <UserLayout />

        <HideForImpersonate>
          <FirstLogin />
        </HideForImpersonate>
      </UserGuard>
    </AuthGuard>
  ),

  children: [
    { element: <Navigate to="dashboard" />, index: true },
    { path: "dashboard", element: <Dashboard /> },

    {
      path: "online-store",
      children: [
        {
          path: "partnerpackage",
          children: [
            {
              index: true,
              element: (
                <HidePartner>
                  <PartnerPackage />
                </HidePartner>
              ),
            },
            // {
            //   path: "checkout",
            //   element: <Checkout />,
            // },
            // { path: ":name", element: <ProductDetails /> },
          ],
        },

        {
          path: "product-subscription",
          children: [
            { index: true, element: <ProductList /> },
            {
              path: "checkout",
              element: <Checkout />,
            },
            { path: ":name", element: <ProductDetails /> },
          ],
        },
        {
          path: "my-orders",
          children: [
            { index: true, element: <MyOrders /> },
            { path: ":type", element: <MyOrders /> },
            {
              path: "invoice/:id",
              element: <InvoiceDetails />,
            },
          ],
        },

        {
          path: "spartoken",
          children: [
            { index: true, element: <Navigate to="packages" /> },
            {
              path: "packages",
              element: <SparToken />,
            },
            {
              path: "stake",
              element: <Stake />,
            },
          ],
        },

        {
          path: "sponserpackage",
          children: [
            { index: true, element: <Navigate to="sales" /> },
            {
              path: "sales",
              element: <Sponser />,
            },
            {
              path: "fixed",
              element: <FixedRate />,
            },
          ],
        },
      ],
    },
    {
      path: "blogs",
      children: [
        { index: true, element: <BlogPosts /> },
        { path: ":id", element: <BlogPost /> },
      ],
    },
    {
      path: "notices",
      element: <Notices />,
    },
    {
      path: "investor-portal",
      element: <InvestorAmc />,
    },
    {
      path: "income-report",
      element: (
        <HideMenus>
          <IncomeReport />
        </HideMenus>
      ),
    },
    { path: "missed-points", element: <MissedPoints /> },
    { ...businessBuilder },
    { ...genealogy },
    { ...subscriptions },
    { ...financial },
    { ...helpCenter },
    { ...profile },
    { ...tools },
  ],
};

export default user;
