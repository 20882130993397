import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH } from "src/routes/paths";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import useLocales from "src/hooks/useLocales";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

export default function LoginForm() {
  const { login, getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { translate } = useLocales();
  const [showPassword, setShowPassword] = useState(false);
  const passwordRegex = /^\S+$/;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        "Email must be a valid email address or This field cannot contain white space"
      )
      .required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, "This field cannot contain white space"),
  });

  const credentials = {
    user: {
      email: "sam@gmail.com",
      password: "12345678",
      remember: true,
    },
    admin: {
      email: "info@cloudlumen.com",
      password: "12345678",
      remember: true,
    },
  };

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;
  const [openOtp, setOpenOtp] = useState(false);
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState("");

  const onSubmit = async (data) => {
    const {
      status,
      data: username,
      message,
      secret,
    } = await login(data.email, data.password, data.remember);
    if (status) {
      getUser();
      enqueueSnackbar(`Welcome ${username}`);
      reset();
    } else if (secret) {
      setSecret(secret);
      setOpenOtp(true);
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };
  const TfaLogin = async () => {
    const reqData = new FormData();
    const [email, password] = getValues(["email", "password"]);
    reqData.append("email", email);
    reqData.append("password", password);
    reqData.append("code", otp);
    reqData.append("verify", 1);

    try {
      const { data, status } = await axiosInstance.post(
        "api/twofaverify",
        reqData
      );
      if (status === 200) {
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        setSession(data.access_token);
        navigate(0);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {/* <Alert
          sx={{
            fontSize: "0.8rem",
          }}
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                reset(credentials.admin);
              }}
            >
              USE
            </Button>
          }
        >
          Admin email:<b>{credentials.admin.email}</b>/password:
          <b>{credentials.admin.password}</b>
        </Alert> */}

          {/* <Alert
          severity="info"
          sx={{
            fontSize: "0.8rem",
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                reset(credentials.user);
              }}
            >
              USE
            </Button>
          }
        >
          User email:<b>{credentials.user.email}</b>/password:
          <b>{credentials.user.password}</b>
        </Alert> */}

          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <RHFTextField name="email" label={translate("login.emailAddress")} />

          <RHFTextField
            name="password"
            label={translate("login.password")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label={translate("login.remember")} />
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            {translate("login.forgot")}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate("login.login")}
        </LoadingButton>
      </FormProvider>

      <Dialog open={openOtp}>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Two-Factor Authentication (2FA)
          </Typography>
          <Stack spacing={3}>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              label="Enter OTP"
              type="number"
            />

            <Button variant="contained" onClick={TfaLogin}>
              Submit
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
